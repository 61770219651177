import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getPaginatedProducts,
    getProductBySlug,
    getPaginatedServices,
    getServicesByKeyword,
    getProductsByKeyword,
    getTopProducts, getTopServices
} from "lib/services/product";
import {getBrandsByKeyword} from "lib/services/brand";

const initialState = {
    test: 0,
    product: {
        id: 0,
            description: '',
            name: '',
            slug: '',
            type: {},
        category: 'product_category_general',
        subcategory: 'product_category_general',
        strain: '',
            strain_type: '',
            price: 0,
            product_compare: 0,
            images: [''],
            location: {},
        brand: {},
        brand_verified: false,
            weight: '',
            rating: 0,
            reviews_count: 0,
            deal: {},
        locations: ['']
    },
    products: [{
        id: 0,
        description: '',
        name: '',
        slug: '',
        type: {},
        category: 'product_category_general',
        subcategory: 'product_category_general',
        strain: '',
        strain_type: '',
        price: 0,
        images: [''],
        location: {},
        brand: {},
        brand_verified: false,
        weight: '',
        rating: 0,
        reviews_count: 0,
        deal: {},
        locations: ['']
    }

    ],
    services: [{
        "id":0,
        "service_id":"svc002",
        "description":"Testing service 2",
        "name":"service UFO 1234",
        "create_dt":"2022-02-28 06:29:01",
        "brand_id":"113",
        "category":"LIFESTYLE",
        "price":"25.38",
        "price_compare":"25",
        "price_promo_from_dt":"0000-00-00",
        "price_promo_to_dt":"0000-00-00",
        "locations":"37,38",
        "img_01":"{\"cover\":null,\"items\":[]}",
        "profile_id":"28",
        "license":"{\"licenseType\":\"EVENT\",\"licenseNumber\":\"a123\",\"expireDate\":null}",
        "social_link":"[]",
        "sts":"ACTIVE",
        "public_viewable":"Y"
    }],
    productSearchResult: [
        {
            id: 0,
            description: '',
            name: '',
            slug: '',
            type: {},
            category: 'product_category_general',
            strain: '',
            strain_type: '',
            price: 0,
            images: [''],
            location: {},
            brand: {},
            brand_verified: false,
            weight: '',
            rating: 0,
            reviews_count: 0,
            deal: {},
            locations: ['']
        }
    ],

    serviceSearchResult: [{
        "id":0,
        "service_id":"svc002",
        "description":"Testing service 2",
        "name":"service UFO 1234",
        "create_dt":"2022-02-28 06:29:01",
        "brand_id":"113",
        "category":"LIFESTYLE",
        "price":"25.38",
        "price_compare":"25",
        "price_promo_from_dt":"0000-00-00",
        "price_promo_to_dt":"0000-00-00",
        "locations":"37,38",
        "img_01":"{\"cover\":null,\"items\":[]}",
        "profile_id":"28",
        "license":"{\"licenseType\":\"EVENT\",\"licenseNumber\":\"a123\",\"expireDate\":null}",
        "social_link":"[]",
        "sts":"ACTIVE",
        "public_viewable":"Y"
    }],

    topProducts: [{
        id: 0,
        description: '',
        name: '',
        slug: '',
        type: {},
        category: 'product_category_general',
        subcategory: 'product_category_general',
        strain: '',
        strain_type: '',
        price: 0,
        images: [''],
        location: {},
        brand: {},
        brand_verified: false,
        weight: '',
        rating: 0,
        reviews_count: 0,
        deal: {},
        locations: ['']
    }],

    topServices: [{
        "id":0,
        "service_id":"svc002",
        "description":"Testing service 2",
        "name":"service UFO 1234",
        "create_dt":"2022-02-28 06:29:01",
        "brand_id":"113",
        "category":"LIFESTYLE",
        "price":"25.38",
        "price_compare":"25",
        "price_promo_from_dt":"0000-00-00",
        "price_promo_to_dt":"0000-00-00",
        "locations":"37,38",
        "img_01":"{\"cover\":null,\"items\":[]}",
        "profile_id":"28",
        "license":"{\"licenseType\":\"EVENT\",\"licenseNumber\":\"a123\",\"expireDate\":null}",
        "social_link":"[]",
        "sts":"ACTIVE",
        "public_viewable":"Y"
    }],

}

export const getProduct = createAsyncThunk(
    'listing/getProductBySlug',
    async ({slug}, {getState, rejectWithValue,fulfillWithValue}) => {
        try {
            const product = await getProductBySlug(slug)
            return fulfillWithValue(product)
        } catch (err) {
            console.log('get product by slug thunk failed: ', err)
            rejectWithValue(err)
        }
    }
)

export const getPaginatedProductsThunk = createAsyncThunk(
    'product/getPaginatedProducts',
    async ({page, filter, sort}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const products = await getPaginatedProducts()
            return fulfillWithValue(products)
        } catch (err) {
            console.log('get paginated products failed: ', err)
            rejectWithValue(err)
        }
    }
)

export const getPaginatedServicesThunk = createAsyncThunk(
    'service/getPaginatedServices',
    async ({page, filter, sort}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const services = await getPaginatedServices()
            return fulfillWithValue(services)
        } catch (err) {
            console.log('get paginated products failed: ', err)
            rejectWithValue(err)
        }
    }
)

export const searchProductByKeywordThunk = createAsyncThunk(
    'product/getProductsByKeyword',
    async ({searchKeyword}, {fulfillWithValue, rejectWithValue}) => {
        try {
            if (!!searchKeyword) {
                const {getProductsByKeyword:products} = await getProductsByKeyword(searchKeyword)
                if (products.length > 100) {
                    console.log('get products res : ', products.slice(products.length - 1)[0].name)
                }
                return fulfillWithValue(products)
            }
        } catch(err) {
            console.log('get products by keyword thunk failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const searchServiceByKeywordThunk = createAsyncThunk(
    'service/getServicesByKeyword',
    async ({searchKeyword}, {fulfillWithValue, rejectWithValue}) => {
        try {
            if (!!searchKeyword) {
                const {getServicesByKeyword:services} = await getServicesByKeyword(searchKeyword)
                if (services.length > 100) {
                    console.log('get services res : ', services.slice(services.length - 1)[0].name)
                }
                return fulfillWithValue(services)
            }
        } catch(err) {
            console.log('get services by keyword thunk failed: ', err)
            return rejectWithValue(err)
        }
    }
)

export const getTopProductsThunk = createAsyncThunk(
    'product/getTopProducts',
    async ({num}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const products = await getTopProducts(num)
            return fulfillWithValue(products)
        } catch (err) {
            console.log('get top products failed: ', err)
            rejectWithValue(err)
        }
    }
)

export const getTopServicesThunk = createAsyncThunk(
    'service/getTopServices',
    async ({num}, {fulfillWithValue, rejectWithValue}) => {
        try {
            const services = await getTopServices(num)
            return fulfillWithValue(services)
        } catch (err) {
            console.log('get top services failed: ', err)
            rejectWithValue(err)
        }
    }
)


export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        increment: (state, action) => {
            state.test += action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.product = action.payload
        })
        builder.addCase(getPaginatedProductsThunk.fulfilled, (state, action) => {
            state.products = action.payload.filter(product => product.name !== '' && product.slug !== '')
        })
        builder.addCase(getPaginatedServicesThunk.fulfilled, (state, action) => {
            // console.log('here !!!!!!!!!! service: ', action.payload.length)
            state.services = action.payload
        })
        builder.addCase(searchProductByKeywordThunk.fulfilled, (state, action) => {
            state.productSearchResult = action.payload
        })
        builder.addCase(searchServiceByKeywordThunk.fulfilled, (state, action) => {
            state.serviceSearchResult = action.payload
        })
        builder.addCase(getTopProductsThunk.fulfilled, (state, action) => {
            if (!!action.payload) {
                state.topProducts = action.payload.filter(product => product.name !== '' && product.slug !== '')
            } else {
                state.topProducts = action.payload
            }
        })
        builder.addCase(getTopServicesThunk.fulfilled, (state, action) => {
            state.topServices = action.payload
        })
    }
})

export const { increment } = productSlice.actions

export default productSlice.reducer
