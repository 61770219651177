import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {createWrapper, HYDRATE} from 'next-redux-wrapper'

import authReducer from "lib/features/authSlice";
import geoReducer from "lib/features/geoSlice";
import locationReducer from "lib/features/locationSlice";
import mapReducer from "lib/features/mapSlice";
import brandReducer from 'lib/features/brandSlice'
import productReducer from 'lib/features/productSlice'


const combinedReducer = combineReducers({
  auth: authReducer,
  geo: geoReducer,
  location: locationReducer,
  map: mapReducer,
  brand: brandReducer,
  product: productReducer
})

const reducer = (state, action) => {
  if (state) {
    // console.log('at redux: ', state.product.products.length)
  }
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload
    }
    // if (state.product) nextState.product = state.product;
    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

let store

const makeStore = () => {
  store = configureStore({
    reducer,
    devTools: true
  })
  return store
}

export {store}
export const wrapper = createWrapper(makeStore, {debug: false})
